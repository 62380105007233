// .markdown {
//   overflow-x: auto;
//   overflow-y: hidden;
//   white-space: normal;

//   h1 {
//     font-size: 24px !important;
//   }

//   h2 {
//     font-size: 22px !important;
//   }

//   h3 {
//     font-size: 20px !important;
//     margin-bottom: 4px;
//   }

//   h4 {
//     font-size: 18px !important;
//   }

//   h5 {
//     font-size: 16px !important;
//   }

//   ol,
//   ul {
//     white-space: initial;
//     // line-height: 1.6rem !important;
//   }

//   table {
//     border-collapse: collapse;
//     margin: 10px 0px;
//   }

//   th,
//   td {
//     text-align: left;
//     padding: 6px 13px;
//     border: 1px solid black;
//   }

//   li {
//     margin: 10px 0px;
//     white-space: normal;
//     text-align: start;

//     p {
//       margin: 0;
//       padding: 0;
//       display: inline;
//     }
//   }

//   p {
//     white-space: normal ;
//     margin: 10px 0px;
//     padding: 0;
//     line-height: normal;
//   }

//   span {
//     overflow: inherit !important;
//     text-overflow: inherit !important;
//     white-space: pre !important;
//   }
// }

.codeHeader {
  border-radius: 10px;
  color: white;
  padding: 8px 0px 0px 0px;
  background: #666;
  margin-top: 20px;
}

.codeHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding-bottom: 8px;
  padding: 0 5px;

  p {
    text-align: center;
    padding-left: 5px;
    margin: 0 !important;
  }



  .headerWrapper {
    padding-right: 5px;
    display: flex;
    align-items: center;

    & > button > span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 5px;
    }
  }
}

.copyBtn {
  cursor: pointer;
  font-size: 15px;

  img {
    filter: invert(1);
    width: 17px;
  }
}

.code_action_button {
  background-color: #fff9f9 !important;
  color: #000 !important;
  border-radius: 8px !important;
  // padding: 4px 10px !important;
  outline: none !important;
  border: none !important;
  margin-right: 1rem !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 10px !important;
  height: 31px !important;


  img {
    margin-left: 5px;
  }
}

.code_empty_box {
  position: relative;
  .copy_btn_code {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
